import * as React from 'react';
import './Emails.scss';
import Dialog from '../../../../components/Dialog';
import { EditContactProps } from '../EditContact';
import { connect } from 'react-redux';
import { Dispatch, Action } from '../../../../models';
import { getEmails } from '../../../../redux/actions/Contact';
import { Contact } from '../../../../models/Contact';
import { EmailSent } from '../../../../models/EmailSent';
import moment from 'moment';
import { popups, pushPopup } from '../../../../utils/popups';
import routes from "../../../routes";
import {Language} from "../../../../models/Options";
import {User} from "../../../../models/User";
import {FormikInputSelect} from "../../../../components/Form";

type Props = {
	getEmails: (id: string) => any;
	contact: Contact;
} & EditContactProps;

const Emails = (props: Props): JSX.Element => {
	const [emails, setEmails] = React.useState([]);
	const { getEmails, contact } = props;
	const bindEmails = () => {
		getEmails(contact.id).then((res: Action) => {
			setEmails(res.payload);
		});
	};

	React.useEffect(() => {
		contact && bindEmails();
	}, [contact]);

	const viewContent = ({ content, subject }: any) => {
		pushPopup(popups.Alert, { innerHtml: content, title: subject });
	};

	return (
		<Dialog title="Emails" className="dialog--emails-sent">
			<div className="inner-content">
				{emails.map((emailSent: EmailSent) => {
					const { id, email, sent_at, email_try, subject, content, sent_by_user } = emailSent;
					const addresses = JSON.parse(email);
					let json_content;
					let json_content_ba;
					if(email_try.can_send_token == 'after_call_inquiry' && content){
						try {
							json_content = JSON.parse(content);
						} catch(err) {
							// handle error
							json_content = null;
						}
					}
					if(email_try.can_send_token == 'booking_advisor' && content){
						try {
							json_content_ba = JSON.parse(content);
						} catch(err) {
							// handle error
							json_content_ba = null;
						}
					}
					return (
						<div className="email-row" key={id}>
							<div className="row">
								<span className="row__label">Email type:</span>{' '}
								<span className="row__value">
									{email_try && email_try.email_try}
								</span>
							</div>
							{subject && (
								<div className="row">
									<span className="row__label">Subject:</span>{' '}
									<span className="row__value">{subject}</span>
								</div>
							)}
							<div className="row">
								<span className="row__label">Language:</span>{' '}
								<span className="row__value">
									{emailSent &&
									emailSent.language &&
									emailSent.language.language}
								</span>
							</div>
							{json_content_ba && (
								<>
									<div className="row">
										<span className="row__label">Search URL:</span>{' '}
										<span className="row__value">
											<a href={json_content_ba.ba_search_url} target="_blank">{json_content_ba.ba_search_url}</a>
									</span>
									</div>
									{json_content_ba.ba_urls.length > 0 && (
										<>
											<span className="row__label">URLs:</span>
											<ul style={{marginLeft: 20, marginTop: 10}}>
												{json_content_ba.ba_urls.map((url: any, index) => {
													return (
														<li className="row__value" key={index} style={{marginBottom: 10}}>
															<a target="_blank" href={url}>{url}</a>
														</li>
													);
												})}
											</ul>
										</>
									)}
									<div className="row">
										<span className="row__label">Intro Type:</span>{' '}
										<span className="row__value">
											{json_content_ba.ba_intro_type}
										</span>
									</div>
									{json_content_ba.ba_custom_text  && (
										<div className="row">
											<span className="row__label">Custom Text:</span>{' '}
											<span className="row__value">
											{json_content_ba.ba_custom_text}
										</span>
										</div>
									)}
									{json_content_ba.ba_currency_id  && (
										<div className="row">
											<span className="row__label">Currency:</span>{' '}
											<span className="row__value">
											{json_content_ba.ba_currency_id}
										</span>
										</div>
									)}
								</>
							)}
							{json_content && (
								<>
									<div className="row">
										<span className="row__label">Guests:</span>{' '}
										<span className="row__value">
										{json_content.guests_no}
									</span>
									</div>
									<div className="row">
										<span className="row__label">Book from:</span>{' '}
										<span className="row__value">
										{moment(json_content.start_date).format('dddd DD MMMM YYYY')}
									</span>
									</div>
									<div className="row">
										<span className="row__label">Book to:</span>{' '}
										<span className="row__value">
										{moment(json_content.end_date).format('dddd DD MMMM YYYY')}
									</span>
									</div>
								</>
							)}
							<div className="row">
								<span className="row__label">Emails:</span>{' '}
								<span className="row__value">{addresses.join(', ')}</span>
							</div>
							<div className="row">
								<span className="row__label">Sent on:</span>{' '}
								<span className="row__value">
									{moment(sent_at).format('DD-MM-YYYY - HH:mm')}
								</span>
							</div>
							<div className="row">
								<span className="row__label">Sent by:</span>{' '}
								<span className="row__value">
									{sent_by_user && sent_by_user.display_name}
								</span>
							</div>
							{content && (email_try.can_send_token != 'booking_advisor') && (email_try.can_send_token != 'after_call_inquiry') && (
								<div className="row">
									<span
										className="link"
										onClick={() => viewContent({ content, subject })}
									>
										View content
									</span>
								</div>
							)}
						</div>
					);
				})}
			</div>
			<div className="dialog__footer">
				<div
					className="link"
					onClick={() => {
						pushPopup(popups.SendEmail, {
							...props,
							contact,
							onDismiss: bindEmails
						});
					}}
				>
					Send new email
				</div>
				<div
					className="link"
					onClick={() => {
						const { id } = props.match.params;
						props.history.push(routes.contact.customEmail.base(id));
					}}
				>
					Send custom email
				</div>
			</div>
		</Dialog>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getEmails: (id: string) => dispatch(getEmails(id))
	};
};

export default connect(
	null,
	mapDispatchToProps
)(Emails);
