import { get, post, patch } from '../utils';

export type FormData$UpdateProfile = {
	display_name: string;
	user_email: string;
	user_phone: string;
	user_job_title: string;
};

export type FormData$UpdatePreferred = {
	default_country_id?: number;
	default_region_id?: number;
	default_country_phone_id?: number;
	default_main_language?: string;
};

async function getProfile(): Promise<any> {
	return get('/profile');
}

async function updateProfile(data: FormData$UpdateProfile): Promise<any> {
	return post('/profile', data);
}

async function updatePreferred(data: FormData$UpdatePreferred): Promise<any> {
	return patch('/profile', data);
}

export default { getProfile, updateProfile, updatePreferred };
