import * as React from 'react';
import './Profile.scss';
import { RouterProps } from 'react-router';
import Dialog from '../../../components/Dialog';
import {
	FormikForm,
	FormikButtonSubmit,
	FormikInputText,
	FormGroup,
	formikSubmit, Option
} from '../../../components/Form';
import { FormikProps, FormikActions } from 'formik';
import { connect } from 'react-redux';
import User from '../../../redux/entities/User';
import {Language, Options} from '../../../models/Options';
import { getOptions, Action$GetOptions } from '../../../redux/actions';
import { FormData$UpdateProfile } from '../../../redux/api/Profile';
import * as Yup from 'yup';
import { FormData$ChangePassword } from '../../../redux/entities/User/models';
import { Action$UpdateProfile } from '../../../redux/actions/Profile';
import changePasswordActionCreator from '../../../redux/User/UserActions/ChangePassword';
import { updateProfileAction } from '../../../redux/User/UserActions';
import { Request$UpdateProfile } from '../../../api/User';
import Preferred from './Preferred';
import CallCenterSettings from "./CallCenterSettings";

type Props = {
	user: User;
	options: Options;
	getOptions: Action$GetOptions;
	updateProfile: Action$UpdateProfile;
	changePassword: any;
} & RouterProps;

type Values = FormData$UpdateProfile;

const validationSchema = Yup.object().shape({
	display_name: Yup.string().required(),
	user_email: Yup.string()
		.email()
		.required(),
	user_phone: Yup.string().nullable(),
	user_job_title: Yup.string()
});

const passwordValidationSchema = Yup.object().shape({
	old_password: Yup.string().required(),
	new_password: Yup.string().required()
});

const Profile = (props: Props): JSX.Element => {
	const { user, options, getOptions } = props;

	React.useEffect(() => {
		if (!options) {
			getOptions();
		}
	}, []);

	const initialValues = {
		display_name: user.display_name,
		user_email: user.user_email,
		user_phone: user.user_phone,
		user_job_title: user.user_job_title,
		extra_data: user.extra_data
	};

	const onSubmit = (values: Values, actions: FormikActions<Values>) => {
		const { updateProfile } = props;
		formikSubmit(values, actions, updateProfile);
	};

	const onPasswordSubmit = (
		values: PasswordValues,
		actions: FormikActions<PasswordValues>
	) => {
		const { changePassword } = props;
		formikSubmit(values, actions, changePassword);
	};

	const passwordInitialValues = {
		new_password: '',
		old_password: ''
	};

	return (
		<div className="page page--profile">
			<Dialog title="Profile Settings">
				<FormikForm
					enableReinitialize={true}
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					render={(formProps: FormikProps<Values>) => (
						<Form {...props} {...formProps} />
					)}
				/>
			</Dialog>
			<Preferred />
			{user.role_id <= 2 && (
				<CallCenterSettings {...user} />
			)}
			<Dialog title="Change Password">
				<FormikForm
					initialValues={passwordInitialValues}
					validationSchema={passwordValidationSchema}
					onSubmit={onPasswordSubmit}
					render={(formProps: FormikProps<PasswordValues>) => (
						<PasswordForm {...props} {...formProps} />
					)}
				/>
			</Dialog>
		</div>
	);
};

const Form = (props: Props & FormikProps<Values>) => {
	const { handleSubmit, options, user } = props;
	return (
		<form onSubmit={handleSubmit}>
			<FormGroup double>
				<FormikInputText name="user_email" flat label="Email:" />
				<FormikInputText name="user_phone" flat label="Phone:" />
			</FormGroup>
			<FormGroup double>
				<FormikInputText name="display_name" flat label="English Name (default):" />
				<FormikInputText name="user_job_title" flat label="English Job title (default):" />
			</FormGroup>
			<FormGroup style={{maxHeight: 230, overflow: 'auto', paddingBottom: 20}}>
				{options && options.languages && options.languages.filter(
					(lang: Language) => lang.country_iso != 'en'
				).map((language: any) => {
					return (
						<FormGroup key={language.id} double>
							<FormikInputText name={`extra_data.localized_name.${language.country_iso}`} flat label={`${language.language} Name:`}  />
							<FormikInputText name={`extra_data.localized_job_title.${language.country_iso}`} flat label={`${language.language} Job Title:`} />
						</FormGroup>
				);
				})}
			</FormGroup>
			<FormikButtonSubmit {...props} />
		</form>
	);
};

const mapStateToProps = (store: any) => {
	const { user, options } = store;
	return {
		user,
		options
	};
};

const user = new User();

const mapDispatchToProps = (dispatch: any) => {
	return {
		getOptions: () => dispatch(getOptions()),
		updateProfile: (data: Request$UpdateProfile) =>
			dispatch(updateProfileAction(data)),
		changePassword: (data: FormData$ChangePassword) =>
			dispatch(changePasswordActionCreator(data))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Profile);

type PasswordValues = {
	old_password: string;
	new_password: string;
};

const PasswordForm = (props: Props & FormikProps<PasswordValues>) => {
	return (
		<form>
			<FormGroup double>
				<FormikInputText
					label="Old password"
					type="password"
					flat
					name="old_password"
				/>
				<FormikInputText
					label="New password"
					type="password"
					flat
					name="new_password"
				/>
			</FormGroup>
			<FormikButtonSubmit {...props} title="Change password" />
		</form>
	);
};
