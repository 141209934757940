import { User } from '../../models/User';
import {post} from "../../redux/api/utils";

export type Request$AddUser = {
	display_name: string;
	user_email: string;
	role_id: number;
	user_phone: string;
	user_job_title: string;
};

export type Response$AddUser = { data: User };

/**
 * Creates new team member in database.
 */
export async function addNewUser(
	data: Request$AddUser
): Promise<Response$AddUser> {
	return post(`/user/new`, data);
}
